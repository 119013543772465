// Fondos

    .bg-primary-grad{
        background-image: linear-gradient( 135deg , #3B82F6, #2563EB);
    }

    .bg-dark-grad{
        background-image: linear-gradient( 135deg , #1E293B, #0F172A);
    }

    .bg-principal{

        background-image: url(../images/bg-principal-movil.jpg);
        background-position: center;
        background-size: cover;

        @include media-breakpoint-up(sm){
            background-image: url(../images/bg-principal.jpg);
        }

    }

    .bg-cobertura{

        background-image: url(../images/bg-cobertura.jpg);
        background-position: center;
        background-size: cover;

    }

    .bg-pami{

        background-image: url(../images/bg-pami.jpg);
        background-position: center;
        background-size: cover;

    }

    .bg-bg-700{
        background-color: #334155;
    }

    .bg-bg-800{
        background-color: #1E293B;
    }

// Tipografía

    .fw-black{
        font-weight: 900;
    }


// Iconos

    .bi-lg{	font-size: 1.5rem; }
    .bi-2x{	font-size: 2rem; }
    .bi-3x{	font-size: 3rem; }
    .bi-4x{	font-size: 4rem; }
